import {
  MDBCheckbox,
  MDBFile,
  MDBInput,
  MDBRow,
  MDBTextArea,
} from 'mdb-react-ui-kit'
import { FC, useCallback, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useStore } from '../../store'
//@ts-ignore
import user from './defaultLogo.jpg'
import moment from 'moment'

import { fetchSegments } from '../../api/fetch-segments'
import toast from 'react-hot-toast'
import { extractPhoneNumberFromCsv } from '../../utils/extractNumber'
import { createSegmentFromCsv } from '../../api/create-segment-from-csv'
import MultiselectDropDown from '../custome-component/multiselectComponent'
import BotSchedule from './BotSchedule'
import SegmentFromMultipleOption from './createSegment'

const ConversationSetup: FC<{ compProps: any }> = ({ compProps }) => {
  const store: any = useStore()
  const [selectedSegments, setSelectedSegments] = useState([])

  const { onChangeHandler, errors, disabled, isNewFlow } = compProps

  const onDateChangeHandler = useCallback(
    (data) => {
      onChangeHandler({ target: data })
    },
    [onChangeHandler]
  )
  const setMultipleSegment = (data: any) => {
    setSelectedSegments(data)
    onChangeHandler({
      target: {
        name: 'segmentId',
        value: data,
      },
    })
  }

  const handleSegmentFileUpload = async (file: File) => {
    if (!store.state.name) {
      toast.error('First select the bot Name')

      return
    }
    try {
      const phoneNumbers: string[] = await extractPhoneNumberFromCsv(file)

      const segData = {
        segment_name: `Segment for bot: ${store.state?.name}`,
        segment_description: `Autogenerated by admin panel for bot: ${store.state?.name}`,
        phone_numbers: phoneNumbers,
      }

      try {
        const res = await createSegmentFromCsv(segData)
        store.setState({
          ...store.state,
          segmentId: `${res?.data?.segment?.id}`,
          newBotName: res?.data?.segment?.name,
        })

        toast.success('Segment created successfully')
      } catch (err) {
        const errorMessage =
          err.response?.data?.message?.join(', ') ||
          err.message ||
          'An unknown error occurred'
        toast.error(`${errorMessage}`)
      }
    } catch (error) {
      toast.error(error.message || error)
    }
  }
  const scheduleTime = store?.state?.scheduleTime
    ? new Date(store.state.scheduleTime)
    : null
  const minDate = scheduleTime || new Date()

  return (
    <MDBRow className="">
      {/* <MDBRow className="mb-3">
        <h4>Conversation Setup</h4>
      </MDBRow> */}
      <div className="my-3">
        <p>Bot Icon</p>
        <div className="mb-3">
          <MDBCheckbox
            name="flexCheck"
            checked={store?.state?.useDefaultIcon}
            //  onChange={(ev) => ev.target.checked ? onChangeHandler({target:{name:'status',value:'pinned'}} ) : onChangeHandler({target:{name:'status',value:'enabled'}})}
            onChange={(ev) =>
              onChangeHandler({
                target: { name: 'useDefaultIcon', value: ev.target.checked },
              })
            }
            id="flexCheckDefault2"
            label="Use Default Icon"
            size={5}
            defaultChecked={store?.state?.useDefaultIcon}
          />
        </div>
        {(store?.state.botImage || store?.state?.useDefaultIcon) && (
          <MDBRow className="text-center mb-2">
            <div
              className="text-center mx-auto"
              style={{
                background: 'white',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                border: '2px solid lightgray',
              }}
            >
              <img
                className="mx-auto"
                style={{
                  height: '150px',
                  width: '150px',
                  objectFit: 'contain',
                  borderRadius: '50%',
                }}
                src={
                  store?.state?.useDefaultIcon ? user : store?.state?.botImage
                }
                alt="bot-icon"
              />
            </div>
          </MDBRow>
        )}

        {!store?.state?.useDefaultIcon && (
          <MDBFile
            accept="image/png, image/jpeg"
            // size="sm"
            id="formFileSm"
            size="md"
            disabled={disabled}
            onChange={(ev) => store?.setBotIcon(ev.target?.files?.[0])}
          />
        )}
        {errors?.botIcon && (
          <div className="form-text text-danger">
            {' '}
            Filename must contain only alphanumeric characters, hyphens, and
            underscores.
          </div>
        )}
      </div>

      <div className="mb-3">
        <MDBInput
          label="Conversation Name *"
          type="text"
          onChange={onChangeHandler}
          name="name"
          style={{ background: 'white' }}
          value={store?.state?.name}
          size="md"
          maxLength={30}
        />
        {errors.name && (
          <div className="form-text text-danger"> {errors?.name}</div>
        )}
      </div>

      <div className="mb-3">
        <MDBInput
          label="Bucket Name *"
          type="text"
          onChange={onChangeHandler}
          name="tags"
          style={{ background: 'white' }}
          value={store?.state?.tags}
          size="md"
        />
        {errors.tags && (
          <div className="form-text text-danger"> {errors?.tags}</div>
        )}
      </div>
      <div className="mb-3">
        <MDBCheckbox
          name="flexCheck"
          checked={store?.state?.isPinned}
          //  onChange={(ev) => ev.target.checked ? onChangeHandler({target:{name:'status',value:'pinned'}} ) : onChangeHandler({target:{name:'status',value:'enabled'}})}
          onChange={(ev) =>
            onChangeHandler({
              target: { name: 'isPinned', value: ev.target.checked },
            })
          }
          id="flexCheckDefault2"
          label="Pinned to User's Screen"
          size={5}
          defaultChecked={store?.state?.isPinned}
        />
      </div>
      {!isNewFlow && (
        <div className="mb-3">
          <MDBTextArea
            label="Conversation Description *"
            //@ts-ignore
            onChange={onChangeHandler}
            name="description"
            value={store?.state?.description || ''}
            rows={4}
            size="md"
          />
          {/* <div className="form-text text-danger">
        Name Not Available
      </div> */}
        </div>
      )}
      {!isNewFlow && (
        <div className="mb-3">
          <MDBInput
            label="Purpose Of Conversation *"
            type="text"
            onChange={onChangeHandler}
            name="purpose"
            value={store?.state?.purpose || ''}
            size="md"
          />
        </div>
      )}
      {!isNewFlow && (
        <div className="mb-3">
          <MDBInput
            label="Start Message *"
            type="text"
            onChange={onChangeHandler}
            name="startingMessage"
            value={store?.state?.startingMessage}
            size="md"
          />
          {errors?.startingMessage && (
            <div className="form-text text-danger">Message Not Available</div>
          )}
        </div>
      )}

      <div className="mb-2">
        <MDBCheckbox
          name="flexCheck"
          checked={store?.isBroadcastBot}
          onChange={(ev) => store?.setIsBroadcastBot(ev.target.checked)}
          id="flexCheckDefault"
          label="Notify User"
          size={5}
          defaultChecked
          disabled={disabled}
        />
      </div>

      <div className="d-flex flex-row align-items-center justify-content-between">
        {isNewFlow && (
          <div style={{ width: '40%', marginBottom: '12px' }}>
            <label style={{ marginBottom: '4px' }}>Segment</label>

            <MultiselectDropDown
              onChange={setMultipleSegment}
              disable={
                (selectedSegments.length === 0 && !!store?.state?.segmentId) ||
                !!disabled
              }
            />
          </div>
        )}

        <p className="mx-2">OR</p>
        <div className="mb-3">
          {isNewFlow && (
            <MDBFile
              accept=".csv"
              // size="sm"
              id="formFileSm"
              size="md"
              label="Recipient List"
              disabled={disabled || store?.state?.segmentId}
              onChange={(ev) => handleSegmentFileUpload(ev.target.files[0])}
              // onChange={(ev) => store?.setSegmentFile(ev.target?.files?.[0])}
            />
          )}
        </div>
        <p className="mx-2">OR</p>
        <div className="mb-3">
          {isNewFlow && (
            <SegmentFromMultipleOption
              isDisable={disabled || store?.state?.segmentId}
            />
          )}
        </div>
      </div>
      {((isNewFlow && !selectedSegments.length && store?.state?.segmentId) ||
        (!store?.isBroadcastBot && store?.state?.newBotName)) &&
        !disabled && <p>Selected Bot: {store?.state?.newBotName ?? ''}</p>}

      <BotSchedule onChangeHandler={onChangeHandler} disabled={disabled} />

      {!isNewFlow && (
        <div className="mb-3">
          <ReactDatePicker
            minDate={new Date(moment().subtract(1, 'days').valueOf())}
            selected={store?.state.startDate}
            className="w-100"
            onChange={(value) =>
              onDateChangeHandler({ name: 'startDate', value })
            }
            customInput={<MDBInput label="Start Date*" />}
          />
        </div>
      )}
      <div>
        <ReactDatePicker
          className="w-100"
          // minDate={new Date()}
          // maxDate={moment(new Date()).add(2, 'days').toDate()}
          minDate={minDate}
          selected={store?.state.endDate}
          onChange={(value) => onDateChangeHandler({ name: 'endDate', value })}
          dateFormat="MM/dd/yyyy"
          disabled={store?.state?.scheduleTime == ''}
          customInput={<MDBInput label="End Date*" />}
        />
        {store.state.endDate && (
          <p className="mt-2" style={{ color: '#9e1b32' }}>
            {`This bot will stop working on ${moment(
              store.state.endDate
            ).format('ddd MMM DD YYYY')} at 11:59:59 PM`}
          </p>
        )}
      </div>
    </MDBRow>
  )
}

export default ConversationSetup
