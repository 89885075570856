//update the ip
export const loginUrl = `${process.env.REACT_APP_nl_login_url}/api/login`

export const searchBot = `${process.env.REACT_APP_UCI_BASE_URL}/admin/bot/search`

export const getStartConversationUrl = (bot: string) =>
  `${process.env.REACT_APP_UCI_BASE_URL}/admin/bot/start/${bot}`

export const createBotUrl = `${process.env.REACT_APP_UCI_BASE_URL}/admin/bot`

export const getUpdateBotUrl = (id: string) =>
  `${process.env.REACT_APP_UCI_BASE_URL}/admin/bot/${id}`

export const segmentMappingUrl = `${process.env.REACT_APP_user_segment_url}/v2/segment-bot-mapping`
export const removeBotsFromNlurl = `${process.env.REACT_APP_user_segment_url}/segment-bot-mapping`

export const createSegmentUrl = `${process.env.REACT_APP_UCI_BASE_URL}/admin/user-segment`

export const odkUploadUrl = `${process.env.REACT_APP_UCI_BASE_URL}/admin/form/upload`

export const addLogicUrl = `${process.env.REACT_APP_UCI_BASE_URL}/admin/conversationLogic`

export const getBotByIdUrl = (id: string) =>
  `${process.env.REACT_APP_UCI_BASE_URL}/admin/bot/${id}`

export const getSegmentCountUrl = (segment: string | number) =>
  `${process.env.REACT_APP_nl_url}/v2/segments/${segment}/mentors/count`

export const getSegmentUrl = `${process.env.REACT_APP_nl_url}/segments`
export const createCsvSegmentUrl = `${process.env.REACT_APP_nl_url}/segment/phone`

export const updateTitleDescriptionUrl = (botId: string) =>
  `${process.env.REACT_APP_UCI_BASE_URL}/admin/bot/modifyNotification/${botId}`
export const getSegmentFiltersUrl = `${process.env.REACT_APP_nl_url}/segment-filters`

export const createGeographySegmentUrl = `${process.env.REACT_APP_nl_url}/segment-filters/create`
